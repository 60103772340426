window.onscroll = () => {
  const header = document.querySelector('.header');
  const scrollYCount = window.scrollY;

  if (scrollYCount > 1) {
    header.classList.add('header_fixed');

  } else if (scrollYCount < 1) {
    header.classList.remove('header_fixed');
  }
};