import anime from 'animejs/lib/anime.es.js';

const wave1 = 'M1440 477V435V407V381L1440 155.845C1440 155.845 1397.86 155.879 1361 143C1267 110.155 1265.77 76.4892 1139.19 28.8758C1012.62 -18.7377 886.039 139.974 759.462 155.845C632.885 171.716 506.308 44.7469 379.731 28.8758C253.154 13.0046 126.577 103.773 0 199V275V327V375V425V487V511V527V545L0.000488281 557L0 594C126.577 594 253.154 594 379.731 594C506.308 594 632.885 594 759.462 594C886.039 594 1012.62 594 1139.19 594C1265.77 594 1313.42 594 1440 594L1440 523L1440 477Z',
  wave2 = 'M1440 477V435V407V381L1440 155.845C1440 155.845 1406.04 173 1367 173C1271 173 1265.77 136.614 1139.19 89.0001C1012.62 41.3866 886.039 139.974 759.462 155.845C632.885 171.716 489.577 116.871 363 101C236.423 85.1287 126.577 103.773 0 199V275V327V375V425V487V511V527V545L0.000488281 557L0 594C126.577 594 253.154 594 379.731 594C506.308 594 632.885 594 759.462 594C886.039 594 1012.62 594 1139.19 594C1265.77 594 1313.42 594 1440 594L1440 523L1440 477Z',
  wave3 = 'M1440 477V435V407V381L1440 155.845C1440 155.845 1406.04 173 1367 173C1271 173 1127.58 104.613 1001 57C874.423 9.38655 785 29 685 77.0001C569 132.68 483 165.394 379.731 117C145 7 126.577 103.773 0 199V275V327V375V425V487V511V527V545L0.000488281 557L0 594C126.577 594 253.154 594 379.731 594C506.308 594 632.885 594 759.462 594C886.039 594 1012.62 594 1139.19 594C1265.77 594 1313.42 594 1440 594L1440 523L1440 477Z',
  wave4 = 'M1440 477V435V407V381L1440 155.845C1440 155.845 1299 55 1203 55C1107 55 1013.58 176.613 887 129C760.423 81.3865 675 68.9999 575 117C459 172.68 442.269 165.394 339 117C104.269 7 103 129 0 199V275V327V375V425V487V511V527V545L0.000488281 557L0 594C126.577 594 253.154 594 379.731 594C506.308 594 632.885 594 759.462 594C886.039 594 1012.62 594 1139.19 594C1265.77 594 1313.42 594 1440 594L1440 523L1440 477Z';

const secondWave1 = 'M1443 2C1395 16 1393 39.7391 1317 86C1271 114 1197 180 1011 116C895 76.086 907 54 785 24C671.565 -3.89397 621.889 13.0476 499 86C376.111 158.952 261 138 179 106C97.0001 74 0.000488281 2 0.000488281 2V594H60.5278C122.889 594 243.945 594 366.834 594C489.723 594 610.778 594 733.667 594C856.556 594 977.611 594 1100.5 594C1223.39 594 1320.11 594 1443 594L1443 354V2Z',
  secondWave2 = 'M1443 2C1395 16 1267.69 82.7272 1183 110C1065 148 1025 140 839 76C723 36.086 797 60 675 30C561.565 2.10603 489.723 3.04758 366.833 76C243.944 148.952 175 128 93 96C11 64 0 2 0 2V594H60.5273C122.889 594 243.944 594 366.833 594C489.723 594 610.777 594 733.666 594C856.555 594 977.611 594 1100.5 594C1223.39 594 1320.11 594 1443 594L1443 354V2Z',
  secondWave3 = 'M1443 2C1427 44 1417 76 1329 76C1187.58 76 1207 50 1065 30C943.524 12.8907 855.666 106 733.666 76C620.231 48.106 523.889 -20.9524 401 52C278.111 124.952 215 96 133 64C51 32 0 2 0 2V594H60.5273C122.889 594 243.944 594 366.833 594C489.723 594 610.777 594 733.666 594C856.555 594 977.611 594 1100.5 594C1223.39 594 1320.11 594 1443 594L1443 354V2Z',
  secondWave4= 'M1443 2C1403 22 1427.08 14.1183 1349 64C1277 110 1199 107.268 1119 96C1005.4 80 1035 72 913 42C799.565 14.106 679.889 -30.9524 557 42C434.111 114.952 215 96 133 64C51 32 0 2 0 2V594H60.5273C122.889 594 243.944 594 366.833 594C489.723 594 610.777 594 733.666 594C856.555 594 977.611 594 1100.5 594C1223.39 594 1320.11 594 1443 594L1443 354V2Z';

anime({
  targets: '.promo__svg_main > path',
  easing: 'linear',
  duration: 10000,
  loop: true,
  d: [{ value: [wave1, wave2] }, { value: wave3 }, { value: wave4 }, { value: wave1 }],
});

anime({
  targets: '.promo__svg_secondary > path',
  easing: 'linear',
  duration: 10000,
  loop: true,
  d: [{ value: [secondWave1, secondWave2] }, { value: secondWave3 }, { value: secondWave4 }, { value: secondWave1 }],
});


anime({
  targets: '.promo-down__svg_main > path',
  easing: 'linear',
  duration: 10000,
  loop: true,
  d: [{ value: [wave1, wave2] }, { value: wave3 }, { value: wave4 }, { value: wave1 }],
});

anime({
  targets: '.promo-down__svg_secondary > path',
  easing: 'linear',
  duration: 10000,
  loop: true,
  d: [{ value: [secondWave1, secondWave2] }, { value: secondWave3 }, { value: secondWave4 }, { value: secondWave1 }],
});

function randomMoveLetter(target, x1, x2, y1, y2, deg1, deg2, durationTime) {
  anime({
    targets: target,
    translateX: function() {
      return anime.random(x1, x2);
    },
    translateY: function() {
      return anime.random(y1, y2);
    },
    rotate: function() {
      return anime.random(deg1, deg2);
    },
    easing: 'easeInSine',
    duration: durationTime,
    complete: function() {randomMoveLetter(target, x1, x2, y1, y2, deg1, deg2, durationTime)}
  });
}

randomMoveLetter('.promo__img-letter-block', 350, 150, -40, 30, -30, 30, 6000);

function randomMove(target, x1, x2, y1, y2, durationTime) {
  anime({
    targets: target,
    translateX: function() {
      return anime.random(x1, x2);
    },
    translateY: function() {
      return anime.random(y1, y2);
    },
    easing: 'easeInSine',
    duration: durationTime,
    complete: function() {randomMove(target, x1, x2, y1, y2, durationTime)}
  });
}

randomMove('.promo__rose-circle', -100, 100, -70, 250, 4500);
randomMove('.promo__white-transparent-circle_first', -60, 280, -40, 230, 5200);
randomMove('.promo__white-transparent-circle_second', -200, 100, -300, 100, 6700);
randomMove('.promo__white-transparent-circle_third', -100, 150, -300, 100, 6000);
randomMove('.promo__white-fill-circle', -80, 150, -300, 100, 5500);
randomMove('.promo__circle_big_first', -80, 80, -30, 30, 4200);
randomMove('.promo__circle_big_second', -100, 50, -200, 150, 6100);
randomMove('.promo__circle_small_first', -50, 50, -50, 50, 4000);
randomMove('.promo__circle_small_second',  -300, 150, -350, 100, 4700);
randomMove('.promo__circle_rose', -250, 150, -320, 50, 6000);

randomMove('.promo-down__rose-circle', -50, 50, -70, 50, 6700);
randomMove('.promo-down__white-circle', -50, 50, -70, 50, 6500);
randomMove('.promo-down__blue-circle_first', -50, 50, -70, 30, 4800);
randomMove('.promo-down__blue-circle_second', -50, 50, -70, 50, 5000);

randomMove('.promo-down__small-blue-circle_first', -50, 50, -50, 50, 5600);
randomMove('.promo-down__small-blue-circle_second', -100, 100, -50, 50, 6600);
randomMove('.promo-down__small-blue-circle_third', -50, 50, -50, 20, 3600);

randomMove('.promo-down__small-rose-circle_first', -80, 80, -70, 30, 4600);
randomMove('.promo-down__small-rose-circle_second', -40, 80, -70, 40, 4200);
randomMove('.promo-down__small-rose-circle_third', -120, 120, -40, 40, 6500);

randomMove('.promo-down__small-white-circle_first', -40, 120, -40, 80, 6200);
randomMove('.promo-down__small-white-circle_second', -100, 100, -50, 80, 6400);
randomMove('.promo-down__small-white-circle_third', -100, 100, -70, 80, 6300);

randomMove('.promo-down__dark-blue-circle_first', -100, 100, -30, 20, 6900);
randomMove('.promo-down__dark-blue-circle_second', -100, 100, -20, 20, 6800);

randomMove('.promo-down__big-letter', -20, 20, -10, 10, 4800);
randomMove('.promo-down__small-letter', -20, 20, -10, 10, 4800);
randomMove('.promo-down__white-letter', -20, 20, -10, 10, 4800);











